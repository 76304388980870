.Sidebar {
  position: absolute;
  z-index: 2;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 70px;
  border-right: 1px solid black;
  background: #0111a1;
  min-height: 100vh;
}

.Sidebar-help {
  position: absolute;
  left: 18px;
  bottom: 30px;
  position: fixed;
}

.Sidebar-help p {
  color: #fff;
  text-decoration: none;
}

.Sidebar-help p:hover {
  cursor: pointer;
  color: #fff;
  text-decoration: underline;
}

@media (max-height: 480px) {
  .Sidebar-help {
    display: none;
  }

  .Sidebar {
    overflow: auto;
  }
}
