.Header {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: #2234dd;
  color: #fff;
}

.Header-logo {
  font-weight: bold;
  margin: 12px 5px 0 10px;
  width: 90px;
}

.Header-help {
  margin: 20px 20px 0px 5px;
  font-size: 0.8em;
  font-style: italic;
}

.SidebarButton {
  position: relative;
  border: 1px solid transparent;
  width: 40px;
  height: 40px;
  margin-top: 15px;
  margin-left: 15px;
  border-radius: 5px;
  font-size: 1em;
  background: #0111a1;
  outline: none !important;
  cursor: pointer;
  animation: fade-in 0.5s;
}

.SidebarButton-expanded {
  border-color: #fff;
}

.SidebarButton:hover,
.SidebarButton:focus {
  background: #605e5e;
}

.SidebarButton:active {
  background: #3b3b3b;
}

.SidebarButton img {
  display: block;
  width: 40px;
  height: 40px;
  margin-left: -8px;
  margin-top: -2px;
}

.SidebarButton-badge {
  position: absolute;
  top: -8px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.4em;
  height: 1.4em;
  color: #fff;
  border-radius: 4px;
  font-size: 0.6em;
  font-weight: bold;
  padding: 5px;
}

.SidebarButton-badge-orange {
  background: #e79600;
}

.SidebarButton-badge-green {
  background: #2ecc40;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.SidebarButton-tooltip {
  position: relative;
  display: inline-block;
  margin: 10px auto;
}

.SidebarButton-tooltip .SidebarButton-tooltiptext {
  background-color: black;
  color: #fff;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  padding: 3px 1px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  width: 75px;
  height: 20px;
  bottom: 75%;
  left: 50%;
  opacity: 0;
}

.SidebarButton-tooltip:hover .SidebarButton-tooltiptext {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 2s;
}

.SidebarButton-tooltip:hover .SidebarButton-tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 20%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.Sidebar {
  position: absolute;
  z-index: 2;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 70px;
  border-right: 1px solid black;
  background: #0111a1;
  min-height: 100vh;
}

.Sidebar-help {
  position: absolute;
  left: 18px;
  bottom: 30px;
  position: fixed;
}

.Sidebar-help p {
  color: #fff;
  text-decoration: none;
}

.Sidebar-help p:hover {
  cursor: pointer;
  color: #fff;
  text-decoration: underline;
}

@media (max-height: 480px) {
  .Sidebar-help {
    display: none;
  }

  .Sidebar {
    overflow: auto;
  }
}

.HelpModal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0, 0.75);
}

.HelpModal-content {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  height: 400px;
  color: #fff;
  font-family: arial;
  font-size: 14px;
  position: fixed;
  border-radius: 0.25rem;
}

.HelpModal-text {
  height: 80%;
  overflow: scroll;
}

.HelpModal-header h1 {
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.HelpModal.show {
  display: block;
}

.HelpModal.hide {
  display: none;
}

.HelpModal-close-btn {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.5);
}

.HelpModal-close-btn:hover {
  cursor: pointer;
  color: #fff;
}

.Frame {
  position: relative;
  width: 300px;
  height: 300px;
}

.Frame-empty {
  border: 1px solid #fff;
}

.Frame img {
  max-width: 300px;
  max-height: 300px;
  width: auto;
  height: auto;
}

.Frame-animation-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  transform: translate(-30px, -30px);
  border-radius: 50%;
  background: transparent;
  border: 5px solid #a9a9a9;
  color: #a9a9a9;
  outline: none !important;
  cursor: pointer;
  font-size: 1.5em;
}

.Frame-animation-button:hover {
  color: #e79600;
  border-color: #e79600;
}

.Frame-animation-button:focus {
  color: #e79600;
  border-color: #e79600;
}

.Frame-directional-icon {
  width: 100px;
  height: 100px;
  margin: 5px;
  position: relative;
}

.Frame-timeline {
  display: flex;
  overflow: scroll;
  width: 80%;
  margin: 1rem auto;
}

.Frame-scroll {
  position: relative;
  display: inline-block;
  width: 150px;
  height: 150px;
  margin: 5px;
  left: 50px;
  opacity: 0.75;
}

.Frame-scroll-active {
  outline: 5px solid #e79600;
  opacity: 1;
}

.Frame-scroll:hover {
  cursor: pointer;
  opacity: 1;
}

.Frame-delete {
  text-align: center;
  margin-top: 3vw;
}

.Frame-redo {
  text-align: center;
  margin-top: 3vw;
}

.Frame-delete-redo-button {
  width: 210px;
  height: 35px;
  margin: 10px 10px 0;
  font-size: 1em;
  background: transparent;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  outline: none !important;
  animation: fade-in 0.5s;
}

.Frame-delete-redo-button:hover {
  background: #484848;
}

.Frame-delete-redo-button:active {
  background: #3b3b3b;
}

.Frame-delete-redo-button:focus {
  background: #484848;
}

.Frame-section-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Frame-container {
  position: relative;
  height: 300px;
  width: 300px;
}

.Frame-container-text {
  position: absolute;
}

.Frame-container-text.top-left {
  top: -15px;
  left: 0px;
}

.Frame-container-text.top-center {
  text-align: center;
  top: -15px;
  width: 100%;
}

.Frame-container-text.top-right {
  top: -15px;
  right: 2px;
}

.Frame-container-text.bottom-left {
  bottom: -15px;
  left: 0px;
}

.Frame-container-text.bottom-center {
  text-align: center;
  bottom: -15px;
  width: 100%;
}

.Frame-container-text.bottom-right {
  right: 2px;
  bottom: -15px;
}

.InfoIcon-icon {
  height: 17px;
  width: 17px;
  margin-left: 10px;
  margin-top: 7px;
}

.InfoIcon-icon:hover {
  cursor: pointer;
}

.InfoIcon-text-container {
  position: relative;
}

.InfoIcon-text {
  position: absolute;
  background-color: rgb(34, 34, 34, 0.8);
  width: 180px;
  margin-left: 10px;
  left: 100%;
  top: 10%;
  font-size: 13px;
  padding: 1rem;
  border-radius: 5px;
  text-align: left;
  z-index: 1;
}

.InfoIcon-text a {
  color: #fff;
}

.InfoIcon-text a:hover {
  color: rgb(184, 184, 184);
}

.InfoIcon-text.hide {
  display: none;
}

.GenerateGifForm-container {
  min-height: 100vh;
}

.GenerateGifForm-form {
  display: inline-block;
  margin-bottom: 40px;
}

.GenerateGifForm-input {
  display: block;
  height: 20px;
  width: 200px;
  margin: 15px auto 0;
  border: 1px solid #000;
  font-size: 1em;
  border-radius: 5px;
  padding: 5px;
  outline: none !important;
  text-align: center;
}

.GenerateGifForm-select {
  width: 210px;
  margin: 15px auto 0;
  border: none;
  height: 33px;
  font-size: 1em;
  text-align: center;
}

.GenerateGifForm-input-error {
  border-color: #cc0000;
}

.GenerateGifForm-button {
  width: 210px;
  height: 35px;
  margin-top: 15px;
  font-size: 1em;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  animation: fade-in 0.5s;
}

.GenerateGifForm-button:hover {
  background: #484848;
}

.GenerateGifForm-button:active {
  background: #3b3b3b;
}

.GenerateGifForm-button:focus {
  border-color: #e79600;
  background: #484848;
}

.ColorPicker {
  display: block;
  width: 210px;
  margin: 15px auto 0;
  color: #fff;
  font-size: 1em;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  border: none;
}

.ColorPicker:hover {
  cursor: pointer;
}

.ColorPicker-SketchPicker {
  display: inline-block;
  margin: 15px auto 0;
  font-size: 1em;
  padding: 5px;
  text-align: center;
}

.Preview {
  position: absolute;
  top: 60px;
  left: 70px;
  right: 0;
  bottom: 0;
  background: #2e2e2e;
  transform: translateX(-100%);
  transition: 0.2s transform;
  margin-bottom: 0;
  overflow: auto;
  color: #fff;
}

.Preview-expanded {
  z-index: 1;
  transform: translateX(0);
  transition: 0.2s transform;
  min-height: 100vh;
}

.Preview-muted {
  opacity: 0.2;
  pointer-events: none;
}

.Preview-scrubber {
  text-align: center;
  width: 340px;
  margin: 1rem auto;
}

.Preview-scrubber input {
  width: 300px;
  cursor: pointer;
}

.Preview-scrubber-counter {
  text-align: center;
  color: #fff;
  font-weight: bold;
  width: 200px;
  margin: 0 auto;
}

.Preview-create {
  width: 200px;
  text-align: center;
  margin: 20px auto 0;
}

.Preview-create-button {
  width: 210px;
  height: 35px;
  margin-top: 10px;
  font-size: 1em;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  outline: none !important;
  animation: fade-in 0.5s;
}

.Preview-create-button:hover {
  background: #484848;
}

.Preview-create-button:active {
  background: #3b3b3b;
}

.Preview-create-button:focus {
  border-color: #e79600;
  background: #484848;
}

.Preview-progress-outer {
  width: 210px;
  margin: 15px auto 0;
}

.Preview-progress-inner {
  height: 100%;
  border-radius: 3px;
}

.Preview-progress-success {
  width: 200px;
  margin: 15px auto 40px;
  text-align: center;
  color: #fff;
}

.Preview-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Preview-no-frames {
  width: 360px;
  margin: 0 auto;
  color: #fff;
  text-align: center;
  font-weight: bold;
}

.Burst {
  position: absolute;
  top: 60px;
  left: 70px;
  bottom: 0;
  width: 200px;
  background: #2e2e2e;
  border-right: 1px solid black;
  text-align: center;
  transform: translateX(-100%);
  transition: 0.2s transform;
  color: #fff;
  min-height: 100vh;
}

.Burst-expanded {
  transform: translateX(0);
  transition: 0.2s transform;
}

.Burst-input {
  height: 20px;
  width: 100px;
  margin-bottom: 15px;
  margin-top: 5px;
  border: 1px solid #000;
  font-size: 1em;
  border-radius: 5px;
  padding: 5px;
  outline: none !important;
  text-align: center;
}

.Burst-dropdown {
  height: 33px;
  width: 114px;
  margin-bottom: 15px;
  margin-top: 5px;
  border: 1px solid #000;
  font-size: 1em;
  outline: none !important;
}

.Burst-input-error {
  border-color: #cc0000;
}

.Burst-button {
  width: 110px;
  height: 35px;
  margin-top: 10px;
  font-size: 1em;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  outline: none !important;
}

.Burst-button:hover {
  background: #484848;
}

.Burst-button:active {
  background: #3b3b3b;
}

.Burst-button:focus {
  background: #484848;
  border-color: #e79600;
}

.capturing {
  opacity: 0.5;
  pointer-events: none;
}

.Bound-text {
  margin: 1rem;
  font-size: 17px;
  font-family: Arial, Helvetica, sans-serif;
}

.Burst-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Burst-refresh-icon {
  position: absolute;
  height: 1.25rem;
  margin: 0.7rem 0.5rem;
}

.Burst-refresh-icon:hover {
  cursor: pointer;
}

.Burst-options-container {
  overflow: auto;
  max-height: calc(100vh - 70px);
  background: #2e2e2e;
}

.Settings {
  position: absolute;
  top: 60px;
  left: 70px;
  bottom: 0;
  min-width: 200px;
  background: #2e2e2e;
  border-right: 1px solid black;
  text-align: center;
  transform: translateX(-100%);
  transition: 0.2s transform;
  color: #fff;
  min-height: 100vh;
}

.Settings input[type='checkbox'] {
  cursor: pointer;
}

.Settings-expanded {
  transform: translateX(0);
  transition: 0.2s transform;
}

.Settings-input {
  height: 20px;
  width: 100px;
  margin-bottom: 15px;
  margin-top: 5px;
  border: 1px solid #000;
  font-size: 1em;
  border-radius: 5px;
  padding: 5px;
  outline: none !important;
  text-align: center;
}

.Settings-input-error {
  border-color: #cc0000;
}

.Settings-dropdown {
  height: 33px;
  width: 114px;
  margin-bottom: 15px;
  margin-top: 5px;
  border: 1px solid #000;
  font-size: 1em;
  outline: none !important;
}

.Settings-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Settings-options-container {
  overflow: auto;
  max-height: calc(100vh - 70px);
  background: #2e2e2e;
}

.Folder {
  position: absolute;
  top: 60px;
  left: 70px;
  bottom: 0;
  width: 378px;
  background: #2e2e2e;
  border-right: 1px solid black;
  text-align: center;
  transform: translateX(-100%);
  transition: 0.2s transform;
  color: #fff;
  min-height: 100vh;
}

.Folder-expanded {
  transform: translateX(0);
  transition: 0.2s transform;
}

.Folder-input {
  height: 20px;
  width: 150px;
  margin-bottom: 15px;
  margin-top: 5px;
  border: 1px solid #000;
  font-size: 1em;
  border-radius: 5px;
  padding: 5px;
  outline: none !important;
  text-align: start;
}

.Folder-input-error {
  border-color: #cc0000;
}

.Folder-saved-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
}

.Folder-saved-item {
  position: relative;
  width: 100%;
  z-index: 1;
}

.Folder-saved-item:hover {
  cursor: pointer;
}

.Folder-delete-graph {
  border: transparent;
  position: absolute;
  top: 0px;
  right: 20px;
}

.Folder-saved-graph {
  display: flex;
  margin-bottom: 5px;
  padding-left: 45px;
  padding-right: 45px;
  text-align: start;
}

.Folder-saved-graph > img {
  border-radius: 5px;
}

.Folder-previous-items {
  margin-top: 15px;
}

.Folder-previous-items::-webkit-scrollbar {
  width: 0 !important;
}

.Folder-titles {
  padding-top: 30px;
}

.Folder-item-text {
  display: block;
  width: 225px;
  padding-left: 10px;
}

.Folder-name-text {
  text-align: start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Folder-small-text {
  font-size: 0.75rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Folder-button {
  background: transparent;
  color: #fff;
  cursor: pointer;
  outline: none !important;
  border-radius: 5px;
}

.Folder-save {
  border: 1px solid #fff;
  width: 110px;
  height: 35px;
  margin-top: 10px;
  font-size: 1em;
}

.Folder-delete-text {
  transform: rotate(45deg);
  font-size: 1.25rem;
}

.Folder-button:hover {
  background: #484848;
}

.Folder-button:active {
  background: #3b3b3b;
}

.Folder-button:focus {
  background: #484848;
  border-color: #e79600;
}

.Folder-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 448px) {
  .Folder {
    width: calc(100vw - 70px);
  }

  .Folder-name-text {
    width: 200px;
  }

  .Folder-small-text {
    width: 200px;
  }

  .Folder-saved-graph {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (max-width: 390px) {
  .Folder {
    width: calc(100vw - 70px);
  }

  .Folder-name-text {
    width: 175px;
  }

  .Folder-small-text {
    width: 175px;
  }

  .Folder-saved-graph {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (max-width: 365px) {
  .Folder {
    width: calc(100vw - 70px);
  }

  .Folder-name-text {
    width: 135px;
  }

  .Folder-small-text {
    width: 135px;
  }

  .Folder-saved-graph {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.ErrorToast {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: #d6d8d9;
  font-weight: bold;
  font-size: 1em;
  animation: 0.2s fade-in;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.calculator {
  position: absolute;
  top: 60px;
  right: 0;
  bottom: 0;
  min-height: 100vh;
}

.calculator-burst,
.calculator-settings {
  left: 271px;
}

.calculator-none,
.calculator-preview {
  left: 70px;
}

.calculator-files {
  left: 449px;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

