.SidebarButton {
  position: relative;
  border: 1px solid transparent;
  width: 40px;
  height: 40px;
  margin-top: 15px;
  margin-left: 15px;
  border-radius: 5px;
  font-size: 1em;
  background: #0111a1;
  outline: none !important;
  cursor: pointer;
  animation: fade-in 0.5s;
}

.SidebarButton-expanded {
  border-color: #fff;
}

.SidebarButton:hover,
.SidebarButton:focus {
  background: #605e5e;
}

.SidebarButton:active {
  background: #3b3b3b;
}

.SidebarButton img {
  display: block;
  width: 40px;
  height: 40px;
  margin-left: -8px;
  margin-top: -2px;
}

.SidebarButton-badge {
  position: absolute;
  top: -8px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.4em;
  height: 1.4em;
  color: #fff;
  border-radius: 4px;
  font-size: 0.6em;
  font-weight: bold;
  padding: 5px;
}

.SidebarButton-badge-orange {
  background: #e79600;
}

.SidebarButton-badge-green {
  background: #2ecc40;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.SidebarButton-tooltip {
  position: relative;
  display: inline-block;
  margin: 10px auto;
}

.SidebarButton-tooltip .SidebarButton-tooltiptext {
  background-color: black;
  color: #fff;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  padding: 3px 1px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  width: 75px;
  height: 20px;
  bottom: 75%;
  left: 50%;
  opacity: 0;
}

.SidebarButton-tooltip:hover .SidebarButton-tooltiptext {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 2s;
}

.SidebarButton-tooltip:hover .SidebarButton-tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 20%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
